.card {
    width: calc((100% - 60px) / 4);
    margin: 10px;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(206, 194, 194, 0.75);
    border-radius: 20px;
    background-color: #7D5AC0;
    color: #ffffff;
    .cardHeader {
        display: flex;
        justify-content: space-between;
        .tag {
            border: 1px solid gray;
            padding: 5px 10px;
            border-radius: 20px;
            background-color: #ffffff;
            color: black;
        }
    }
    .cardBody {
        h2 {
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
        }
    }
    .cardFooter {
        p {
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}

.newsWrapper{
    .newsImg{

    }
    .newsTitle{
        padding: 10px;
       p{
        border-left: 2px solid #7D5AC0;
       }
    }
    .newsDesc{

    }
    .videoContainer{

    }
}



.venueCard {
    width: calc((100% - 80px) / 4);
    margin: 10px;
    /* height: 300px; */
    /* max-height: 300px; */
    border-radius: 20px;
    position: relative;
  }
  
  .venueCard img {
    width: 100%;
    height: 250px;
    border-radius: 20px;
    -webkit-filter: brightness(80%);
    filter: brightness(80%);
  }
  
  .venueCard .info {
    padding: 20px;
    position: absolute;
    /* height: 50%; */
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
  }
  
  .venueCard .info .venueName {
    font-size: 19px;
    font-weight: 600;
  }
  
  .venueCard .info .venueLocation {
    color: #a4a4a4;
    display: inline-block;
  }
  .venueCard .info .venueLocation.date {
    color: #a4a4a4;
    margin: 0;
  }
  
  .venueCard .info svg {
    position: absolute;
    right: 30px;
    top: 80px;
    cursor: pointer;
  }
  
  .venueCard .info svg:hover {
    fill: #0956E6;
  }