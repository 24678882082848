@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
.jobContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.jobContainer .containerOne {
  background-color: #ffffff;
  width: 100%;
  height: 30px;
  position: relative;
}

.jobContainer .containerOne .searchBar {
  width: 50%;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 1px 2px 13px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 2px 13px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 2px 13px -6px rgba(0, 0, 0, 0.75);
}

.jobContainer .containerOne .searchBar label {
  width: 5%;
  text-align: center;
}

.jobContainer .containerOne .searchBar input {
  width: 95%;
  padding-left: 20px;
  border: none;
  outline: none;
}

.jobContainer .containerTwo {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 50px;
}

@media (min-width: 320px) and (max-width: 679px) {
  .jobContainer .containerOne .searchBar {
    width: 90%;
  }
  .jobContainer .containerOne .searchBar label {
    width: 10%;
  }
  .jobContainer .containerOne .searchBar input {
    width: 90%;
  }
}
