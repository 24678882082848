@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

$primary: #00b4d8;

//card one

.jobContainer{
    display: flex;
    flex-direction: column;
   .containerOne{
        background-color: #ffffff;
        width: 100%;
        height: 30px;
        position: relative;
        .searchBar{
            width: 50%;
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 100%;
            transform: translate(-50%, -50%);
            background-color: #ffffff;
            border-radius: 10px;
            padding: 10px;
            box-shadow: 1px 2px 13px -6px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 1px 2px 13px -6px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 1px 2px 13px -6px rgba(0, 0, 0, 0.75);
            label {
                width: 5%;
                text-align: center;
            }
            input {
                width: 95%;
                padding-left: 20px;
                border: none;
                outline: none;
            }
        }
    }
    .containerTwo{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
    }
}

@media (min-width: 320px) and (max-width: 679px) {
    .jobContainer{
        .containerOne{
            .searchBar{
                width: 90%;
                label{
                    width: 10%;
                }
                input{
                    width: 90%;
                }
            }
        }
    }
}

// .jobCard {
//     font-family: "Poppins", sans-serif;

//     width: calc((100% - 120px)/3);
//     min-width: 300px;
//     margin: 20px;
//     border-radius: 20px;
//     box-shadow: 0px 0px 4px 0px rgba(0,180,216,0.86);
//     -webkit-box-shadow: 0px 0px 4px 0px rgba(0,180,216,0.86);
//     -moz-box-shadow: 0px 0px 4px 0px rgba(0,180,216,0.86);
//     cursor: pointer;
//     .cardHeader {
//         display: flex;
//         padding: 10px;
//         justify-content: space-between;
//         .info {
//             padding: 10px;

//             .name {
//                 margin: 0;
//                 font-size: 24px;
//                 font-weight: 500;
//             }
//             .location {
//                 margin: 0;
//                 display: flex;
//                 align-items: center;
//                 span {
//                     color: #a4a4a4;
//                     margin-left: 5px;
//                 }
//             }
//         }
//         .package {
//             // width: 15%;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: flex-start;
//             .salary {
//                 margin: 0;
//                 font-size: 18px;
//                 font-weight: 500;
//             }
//             .per {
//                 margin: 0;
//                 font-size: 14px;
//                 color: #a4a4a4;
//             }
//         }
//     }
//     .cardBody {
//         padding: 10px;

//         .jobDesc {
//             font-size: 16px;
//             font-weight: 400;
//             margin: 0;
//         }
//         .shift{
//             display: flex;
//             span{
//                 color: #000000;
//                 margin-right: 5px;
//             }
//             margin-top: 10px;
//             margin-bottom: 0;
//             font-size: 14px;
//             color: #a4a4a4;
//         }
//     }
//     .cardFooter {
//         display: flex;
//         padding: 10px;

//         .designation {
//             background-color: $primary;
//             padding: 10px;
//             color: #ffffff;
//             border-radius: 30px;
//             margin: 0 5px;
//         }
//     }
// }

// @media (min-width: 320px) and (max-width: 770px) {
//     .jobCard{
//         margin: 10px auto;
//         width: calc(100% - 10px);
//         .cardFooter{
//             flex-direction: column;
//             padding: 0;
//             .designation{
//                 width: calc(100% - 10px);
//                 margin: 10px auto;
//             }
//         }
//     }
// }

// job card Two

// .jobCardTwo{
//     font-family: "Poppins", sans-serif;
//     width: calc((100% - 120px)/3);
//     padding: 10px;
//     box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
//     -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
//     -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
//     border-radius: 20px;
//     margin: 10px;
//     .designation{
//         font-size: 24px;
//         font-weight: 700;
//         color: #121212;
//         margin: 0;
//         padding: 0px 10px;
//     }
//     .infoContainer{
//         padding: 10px;
//         .info{
//             display: flex;
//             align-items: center;
//             margin: 0;
//             color: #aaaaaa;
//             margin: 5px 0;
//             span{
//                 margin-left: 10px;
//             }
//         }
//     }
//     .footer{
//         border-top: 1px solid rgba(0, 0, 0, 0.20);
//         display: flex;
//         justify-content: space-between;
//         padding: 10px;
//         .experience{
//             margin: 0;
//             color: #a4a4a4;
//         }
//         .company{
//             margin: 0;
//             color: $primary;
//         }
//     }
// }

// @media (min-width: 425px) and (max-width: 768px) {
//     .jobCardTwo{
//         width: calc((100% - 80px)/2);
//     }
// }

// @media (min-width: 320px) and (max-width: 424px) {
//     .jobCardTwo{
//         width: calc(100% - 40px);

//     }
// }