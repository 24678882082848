@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url("https://use.fontawesome.com/releases/v5.8.2/css/all.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/css/mdb.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js");
@import url("https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.4/umd/popper.min.js");
@import url("https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/js/bootstrap.min.js");

* {
    box-sizing: border-box;
}

$font: "poppins", sans-serif;
$bolder: 700;
$bold: 500;
$normal: 400;

$colorRed: #990f1b;
$colorOrange: #ffb604;
$shadow: 0.5px 1.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
$border: 1px solid black;
$border-radius: 10px;
$bgcolor: #f4f8f8;
$bgcolor2: #f69110;
$bgcolor3: #605e5e;
$bgcolor4: #2f2e2e;
$gradient: linear-gradient(122deg, rgba(246, 145, 16, 1) 27%, rgba(96, 94, 94, 1) 59%);

.mainWrapper {
    width: 100%;
    height: 100vh;
    font-family: $font;
    overflow: hidden;
    // background-color: $bgcolor;
    background-color: white;
    display: flex;
    flex-wrap: nowrap;

    .leftContainer {
        width: 18%;
        height: 100vh;
        box-shadow: $shadow;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        overflow-y: scroll;
        position: relative;

        .logo {
            width: 100%;
            height: 30%;
            z-index: +1;
        }

        .navList {
            width: 100%;
            height: 70%;
            z-index: +1;
            display: flex;
            flex-direction: column;
            justify-content: start;

            div {
                height: 11%;
                position: relative;

                a {
                    display: flex;
                    flex-direction: row;
                    text-decoration: none;
                    color: #f4f8f8;

                    .icon {
                        width: 15%;
                        margin-left: 8%;
                    }

                    span {
                        width: 85%;
                    }
                }

                a.active:after {
                    content: "";
                    display: block;
                    border: 12px solid rgba(255, 253, 253, 0);
                    border-right-color: #f4f8f8;
                    position: absolute;
                    right: 0;
                    margin-left: -12px;
                }

                a:hover {
                }

                a:hover:after {
                    content: "";
                    display: block;
                    border: 12px solid rgba(255, 253, 253, 0);
                    border-right-color: #f4f8f8;
                    position: absolute;
                    right: 0;
                    margin-left: -12px;
                }
            }
        }

        .bg {
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            left: -40px;

            // z-index: -1;
            img {
                height: 100vh;
            }
        }
    }

    .rightContainer.small {
        // width: 85%;
        width: 100%;
        // margin-left: auto;
        height: 100vh;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;

        .topBar {
            // height: 8%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 30px 10px;

            // border-bottom: $border;
            h3 {
                font-weight: 500;
            }

            .toolSpace {
                width: 70px;
                margin-right: 6px;

                svg {
                    // margin-right: 1%;
                }
            }

            .toolSpace.dash {
                position: relative;
                top: 15px;
            }
        }

        .contentContainer {
            height: 90%;
            margin: 12px;
            // box-shadow: $shadow;
            display: flex;
            flex-direction: column;

            .cards {
                width: 100%;
                height: 35%;
                display: flex;
                flex-direction: row;

                // border: $border;
                .cardA {
                    cursor: pointer;
                    width: 25%;
                    min-width: 250px;
                    height: 80%;
                    max-height: 120px;
                    box-shadow: $shadow;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-around;
                    border-radius: $border-radius;
                    color: white;
                    // border: $border;
                    margin: 5px;
                    padding: 5px;
                    background: #ed4787;
                    background: -webkit-linear-gradient(top right, #ed4787, #b756a6);
                    background: -moz-linear-gradient(top right, #ed4787, #b756a6);
                    background: linear-gradient(to bottom left, #ed4787, #b756a6);
                    max-width: 350px;
                    .left {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;

                        .title {
                            // text-transform: uppercase;
                            cursor: pointer;
                        }

                        .svg {
                            color: white;
                        }
                    }

                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: end;
                        // border: $border;
                        .count {
                            font-size: 3rem;
                        }

                        .date {
                            font-size: 0.9rem;
                        }
                    }
                }

                .cardB {
                    cursor: pointer;
                    max-width: 350px;

                    width: 25%;
                    min-width: 250px;

                    height: 80%;
                    max-height: 120px;
                    box-shadow: $shadow;
                    display: flex;
                    flex-wrap: nowrap;
                    // justify-content: space-around;
                    border-radius: $border-radius;
                    color: white;
                    // border: $border;
                    margin: 5px;
                    padding: 5px;
                    background: #7d5ac0;
                    overflow: hidden;
                    // background: -webkit-linear-gradient(top left, #940142, #092D54);
                    // background: -moz-linear-gradient(top left, #940142, #092D54);
                    // background: linear-gradient(to bottom right, #940142, #092D54);
                    position: relative;

                    .left {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .title {
                            // text-transform: uppercase;
                            font-size: 0.8rem;
                            cursor: pointer;
                        }

                        .count {
                            font-size: 3rem;
                            // border: $border;
                        }
                    }

                    svg {
                        position: absolute;
                        bottom: 0;
                        top: 60px;
                        right: 0;
                        left: 0;
                    }
                }

                .cardC {
                    width: 25%;
                    height: 80%;
                    max-width: 350px;

                    min-width: 250px;
                    cursor: pointer;
                    max-height: 121px;
                    box-shadow: $shadow;
                    display: flex;
                    flex-wrap: nowrap;
                    // justify-content: space-around;
                    border-radius: $border-radius;
                    color: white;
                    // border: $border;
                    margin: 5px;
                    padding: 5px;
                    background: #44c4f3;
                    background: -webkit-linear-gradient(top left, #44c4f3, #6491db);
                    background: -moz-linear-gradient(top left, #44c4f3, #6491db);
                    background: linear-gradient(to bottom right, #44c4f3, #6491db);

                    .left {
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .title {
                            // text-transform: uppercase;
                            font-size: 0.8rem;
                            cursor: pointer;
                        }

                        .count {
                            font-size: 3rem;
                        }

                        .badge {
                            letter-spacing: 2px;
                            font-size: 0.8rem;
                            padding: 2px;
                        }
                    }

                    .right {
                        width: 50%;
                        position: relative;
                        .svg {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                        }
                    }
                }
                .cardD {
                    cursor: pointer;

                    width: 25%;
                    min-width: 250px;

                    height: 80%;
                    max-height: 120px;
                    box-shadow: $shadow;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-around;
                    border-radius: $border-radius;
                    color: white;
                    // border: $border;
                    margin: 5px;
                    padding: 5px;
                    background: #ffb92d;
                    background: -webkit-linear-gradient(top left, #ffb92d, #f8805b);
                    background: -moz-linear-gradient(top left, #ffb92d, #f8805b);
                    background: linear-gradient(to bottom right, #ffb92d, #f8805b);

                    .left {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;

                        .title {
                            text-transform: uppercase;
                            cursor: pointer;
                        }

                        .svg {
                            color: white;
                        }
                    }

                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: end;
                        .count {
                            font-size: 3rem;
                        }

                        .date {
                            font-size: 0.9rem;
                        }
                    }
                }
            }

            .dashBottom {
                width: 100%;
                display: flex;
                flex-direction: row;

                .dashBotCard1 {
                    width: 50%;
                    height: 88px;
                    overflow: hidden;
                    margin: 5px;
                    display: flex;
                    flex-wrap: wrap;
                    border-radius: 15px;
                    padding-bottom: 10px;
                    transition: 0.3s;
                    background-color: #e0e0e0;

                    .linkTo {
                        width: 100%;
                        padding: 15px 5px 15px 0px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .titleTag {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;

                            h4 {
                                color: #e0e0e0;
                                padding: 10px 1px;
                                margin-bottom: 0;
                                height: 50px;
                            }

                            .arrow {
                                width: 0;
                                height: 0;
                                border-top: 25px solid transparent;
                                border-bottom: 25px solid transparent;
                            }
                        }

                        a {
                            color: rgb(0, 0, 0);
                        }

                        a:hover {
                            padding: 5px;
                            border-radius: 50%;
                            color: #e0e0e0;
                            box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                        }

                        span {
                            // color: #121212 !important;
                            font-size: 1rem;
                        }
                    }

                    .tableA {
                        height: 400px;
                        padding: 12px;
                        width: 100%;
                        overflow-y: scroll;
                        background-color: white;
                        border-radius: 20px;
                        border-top-width: 3px;
                        border-top-style: solid;
                        border-top-color: #000000;

                        border-bottom-width: 3px;
                        border-bottom-style: solid;
                        margin: 10px;

                        table {
                            thead {
                                tr:first-child {
                                    top: -12px;
                                    position: sticky;
                                    width: 100%;
                                    background: #ffffff;

                                    th {
                                        font-weight: 400;
                                        font-size: medium;
                                        // background-color: #ffffff;
                                    }
                                }
                            }

                            tbody {
                                // background-color: #ffffff;
                                tr {
                                    td {
                                        font-weight: 500;
                                    }
                                }

                                tr:last-child {
                                    td:first-child {
                                        border-bottom-left-radius: 0px;
                                    }

                                    td:last-child {
                                        border-bottom-right-radius: 0px;
                                    }
                                }
                            }
                        }
                    }

                    .tableA::-webkit-scrollbar {
                        display: none;
                    }

                    /* Hide scrollbar for IE, Edge and Firefox */
                    .tableA {
                        -ms-overflow-style: none;
                        /* IE and Edge */
                        scrollbar-width: none;
                        /* Firefox */
                    }
                }

                .dashBotCard1.liveClasses {
                    .linkTo {
                        .titleTag {
                            h4 {
                                background-color: #2573ef;
                            }

                            .arrow {
                                border-left: 25px solid #2573ef;
                            }
                        }

                        a:hover {
                            background-color: #2573ef;
                        }
                    }

                    .tableA {
                        border-bottom-color: #2573ef;

                        table {
                            thead {
                                tr:first-child {
                                    th {
                                        color: #2573ef;
                                    }
                                }
                            }
                        }
                    }
                }

                .dashBotCard1.installments {
                    .linkTo {
                        .titleTag {
                            h4 {
                                background-color: #cc2037;
                            }

                            .arrow {
                                border-left: 25px solid #cc2037;
                            }
                        }

                        a:hover {
                            background-color: #cc2037;
                        }
                    }

                    .tableA {
                        border-bottom-color: #cc2037;

                        table {
                            thead {
                                tr:first-child {
                                    th {
                                        color: #cc2037;
                                    }
                                }
                            }
                        }
                    }
                }

                .dashBotCard1.liveClasses.show {
                    height: 500px;
                }
                .dashBotCard1.installments.show {
                    height: 500px;
                }

                .dashBotCard1:hover {
                    cursor: pointer;
                    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                    -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                    -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                }
            }
            .dashBottom2 {
                width: 100%;
                display: flex;
                flex-direction: row;

                .dashBotCard1 {
                    width: 50%;
                    height: 88px;
                    overflow: hidden;
                    margin: 5px;
                    display: flex;
                    flex-wrap: wrap;
                    border-radius: 15px;
                    padding-bottom: 10px;
                    transition: 0.3s;
                    background-color: #e0e0e0;

                    .linkTo {
                        width: 100%;
                        padding: 15px 5px 15px 0px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .titleTag {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;

                            h4 {
                                color: #e0e0e0;
                                padding: 10px 1px;
                                margin-bottom: 0;
                                height: 50px;
                            }

                            .arrow {
                                width: 0;
                                height: 0;
                                border-top: 25px solid transparent;
                                border-bottom: 25px solid transparent;
                            }
                        }

                        a {
                            color: rgb(0, 0, 0);
                        }

                        a:hover {
                            padding: 5px;
                            border-radius: 50%;
                            color: #e0e0e0;
                            box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                        }

                        span {
                            // color: #121212 !important;
                            font-size: 1rem;
                        }
                    }

                    .tableA {
                        height: 400px;
                        padding: 12px;
                        width: 100%;
                        overflow-y: scroll;
                        background-color: white;
                        border-radius: 20px;
                        border-top-width: 3px;
                        border-top-style: solid;
                        border-top-color: #000000;

                        border-bottom-width: 3px;
                        border-bottom-style: solid;
                        margin: 10px;

                        table {
                            thead {
                                tr:first-child {
                                    top: -12px;
                                    position: sticky;
                                    width: 100%;
                                    background: #ffffff;

                                    th {
                                        font-weight: 400;
                                        font-size: medium;
                                        // background-color: #ffffff;
                                    }
                                }
                            }

                            tbody {
                                // background-color: #ffffff;
                                tr {
                                    td {
                                        font-weight: 500;
                                    }
                                }

                                tr:last-child {
                                    td:first-child {
                                        border-bottom-left-radius: 0px;
                                    }

                                    td:last-child {
                                        border-bottom-right-radius: 0px;
                                    }
                                }
                            }
                        }
                    }

                    .tableA::-webkit-scrollbar {
                        display: none;
                    }

                    /* Hide scrollbar for IE, Edge and Firefox */
                    .tableA {
                        -ms-overflow-style: none;
                        /* IE and Edge */
                        scrollbar-width: none;
                        /* Firefox */
                    }
                }

                .dashBotCard1.scores {
                    .linkTo {
                        .titleTag {
                            h4 {
                                background-color: #15bb5a;
                            }

                            .arrow {
                                border-left: 25px solid #15bb5a;
                            }
                        }

                        a:hover {
                            background-color: #15bb5a;
                        }
                    }

                    .tableA {
                        border-bottom-color: #15bb5a;

                        table {
                            thead {
                                tr:first-child {
                                    th {
                                        color: #15bb5a;
                                    }
                                }
                            }
                        }
                    }
                }
                .dashBotCard1.enquiry {
                    .linkTo {
                        .titleTag {
                            h4 {
                                background-color: #6411ad;
                            }

                            .arrow {
                                border-left: 25px solid #6411ad;
                            }
                        }

                        a:hover {
                            background-color: #6411ad;
                        }
                    }

                    .tableA {
                        border-bottom-color: #6411ad;

                        table {
                            thead {
                                tr:first-child {
                                    th {
                                        color: #6411ad;
                                    }
                                }
                            }
                        }
                    }
                }
                .dashBotCard1.scores.show {
                    height: 500px;
                }
                .dashBotCard1.enquiry.show {
                    height: 500px;
                }

                .dashBotCard1:hover {
                    cursor: pointer;
                    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                    -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                    -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                }
            }

            .contentTop {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: end;
            }

            .table.show {
                width: 100%;
                height: 100%;
                padding: 1%;
                position: relative;
                display: flex;
                flex-direction: column;

                // justify-content: space-between;
                .dataSearch {
                    width: 100%;
                    margin-bottom: 40px;
                    position: relative;

                    input,
                    a .exportBtn {
                        width: 15% !important;
                        position: absolute !important;
                        right: 0 !important;
                    }
                }

                .container {
                    overflow-y: visible;

                    // overflow-x: scroll;
                    table {
                        thead[data-test="table-foot"] {
                            display: none;
                        }
                    }
                }

                .container::-webkit-scrollbar {
                    display: none;
                }

                // table{
                //     margin-top: 30px;
                //     thead{
                //         tr{
                //             th:first-child{
                //                 border-radius: 15px 0px 0px 15px;
                //             }
                //             th:last-child{
                //                 border-radius: 0px 15px 15px 0px;
                //             }
                //         }
                //         tr:hover{
                //             th:first-child{
                //                 border-radius: 15px 0px 0px 15px;
                //                 background-color: #212529  !important;
                //             }
                //             th:last-child{
                //                 border-radius: 0px 15px 15px 0px;
                //                 background-color: #212529  !important;
                //             }
                //             th{
                //                 background-color: #212529  !important;
                //             }
                //         }
                //     }
                //     tbody{
                //         tr{
                //             td{
                //                 span{
                //                     min-width: 50%;
                //                     padding:10px 5px;
                //                 }
                //             }
                //         }
                //     }
                // }
                // .tableBtn{
                //     position: relative;
                //     button{
                //         text-transform: capitalize;
                //         position: absolute;
                //         right: 0;
                //     }
                // }
            }

            .table.hide {
                display: none;
            }

            .form.show {
                form {
                    // position: relative;
                    margin: auto;

                    // width: 70%;
                    button {
                        // position: absolute;
                        // right: 50%;
                        // transform: translateX(-50%);
                        margin: 2px;
                        // margin-top: 2%;
                    }

                    //                 .instr {
                    //                     width: 100%;
                    //                     height: 100%;
                    //                     display: flex;
                    //                     flex-direction: row;
                    //                     justify-content: space-between;
                    //                     .instr_select {
                    //                         width: 100%;
                    //                         height: 100%;
                    //                         // border: $border;
                    //                         display: flex;
                    //                         flex-direction: column;
                    //                         justify-content: space-between;
                    //                         .add {
                    //                             width: 100%;
                    //                             min-height: 100px;
                    //                             border: $border;
                    //                             border-radius: $border-radius;
                    //                             text-align: center;
                    //                             display: flex;
                    //                             flex-direction: row;
                    //                             align-items: center;
                    //                             // justify-content: start;
                    //                             cursor: pointer;
                    //                             margin-top: 5px;
                    //                             padding: 0px 5px;
                    //                             select {
                    //                                 width: 30%;
                    //                             }
                    //                         }
                    //                     }
                    //                     .instr_table {
                    //                         width: 00%;
                    //                         // border: $border;
                    //                     }
                    //                 }

                    //             }
                    //             .formTwoWrapper{
                    //                 display: flex;
                    //                 flex-direction: column;
                    //                 align-items: center;
                    //                 .formInputs{
                    //                     display: flex;
                    //                     .formWrapper.user{
                    //                         width: 40%;
                    //                     }

                    //                     .cardPreview.user{
                    //                         width: 60%;
                    //                         .instructorTable {
                    //                             width: 100%;
                    //                             display: flex;
                    //                             justify-content: space-between;
                    //                             align-items: center;
                    //                             .instructorForm {
                    //                                 width: 40%;
                    //                                 margin: 0;

                    //                                 .inputCard {
                    //                                     background-color: #ffffff;
                    //                                     box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                    //                                     -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                    //                                     -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                    //                                     margin: 10px;
                    //                                     border-radius: 20px;
                    //                                     padding: 20px;
                    //                                     display: flex;
                    //                                     flex-wrap: wrap;
                    //                                     justify-content: space-between;
                    //                                     align-items: center;
                    //                                     h2 {
                    //                                         font-size: 16px;
                    //                                         margin: 0;
                    //                                     }
                    //                                     svg {
                    //                                         cursor: pointer;
                    //                                     }
                    //                                     .timingContainer {
                    //                                         display: none;
                    //                                         width: 100%;
                    //                                     }
                    //                                     .timingContainer.show {
                    //                                         display: flex;
                    //                                         flex-wrap: wrap;
                    //                                         justify-content: space-between;
                    //                                         align-items: center;
                    //                                         padding: 0;
                    //                                         .timingSlots {
                    //                                             padding: 5px 10px;
                    //                                             border-radius: 20px;
                    //                                             background-color: #4285f4;
                    //                                             color: #ffffff;
                    //                                             margin: 5px 2px;
                    //                                             font-size: 12px;

                    //                                         }
                    //                                         form{
                    //                                             display: inline-block;
                    //                                             width: 100%;
                    //                                             display: flex;
                    //                                             justify-content: space-between;
                    //                                             align-items: center;
                    //                                             .inputContainer{
                    //                                                 display: inline-block;
                    //                                                 label{
                    //                                                     font-size: 12px;
                    //                                                     margin: 0;
                    //                                                 }
                    //                                                 input{
                    //                                                     font-size: 12px;
                    //                                                 }
                    //                                             }
                    //                                             button{
                    //                                                 border: none;
                    //                                                 background:none;
                    //                                                 svg{
                    //                                                     margin: 0;
                    //                                                 }
                    //                                             }
                    //                                         }
                    //                                     }
                    //                                 }
                    //                             }
                    //                             .instructorView {
                    //                                 width: 60%;
                    //                                 margin: 0;
                    //                                 padding: 0;

                    //                                 .tableFormat {

                    //                                     .row{
                    //                                         display: flex;
                    //                                         align-items: center;
                    //                                         padding: 20px;
                    //                                         .title{
                    //                                             width: 18%;
                    //                                             p{
                    //                                                 margin: 0;
                    //                                             }
                    //                                         }
                    //                                         .slots{
                    //                                             width: 68%;
                    //                                             display: flex;
                    //                                             overflow-x: scroll;
                    //                                             .timingSlots {
                    //                                                 border: none;
                    //                                                 outline: none;
                    //                                                 min-width: 100px;
                    //                                                 padding: 5px 10px;
                    //                                                 border-radius: 20px;
                    //                                                 background-color: #4285f4;
                    //                                                 color: #ffffff;
                    //                                                 margin: 5px 10px;
                    //                                                 font-size: 12px;
                    //                                                 // display: flex;
                    //                                                 // align-items: center;
                    //                                                 // justify-content: end;
                    //                                                 position: relative;
                    //                                                 span{
                    //                                                     background-color: $colorOrange;
                    //                                                     padding: 5px 10px;
                    //                                                     border-radius: 50%;
                    //                                                     position: absolute;
                    //                                                     right: -20px;
                    //                                                     top: 0;
                    //                                                     bottom: 0;
                    //                                                 }
                    //                                             }
                    //                                             .timingSlots.selected {
                    //                                                 border: none;
                    //                                                 outline: none;
                    //                                                 min-width: 100px;
                    //                                                 padding: 5px 10px;
                    //                                                 border-radius: 20px;
                    //                                                 background-color: #121212;
                    //                                                 color: #ffffff;
                    //                                                 margin: 5px 10px;
                    //                                                 font-size: 12px;
                    //                                                 // display: flex;
                    //                                                 // align-items: center;
                    //                                                 // justify-content: end;
                    //                                                 position: relative;
                    //                                                 span{
                    //                                                     background-color: $colorOrange;
                    //                                                     padding: 5px 10px;
                    //                                                     border-radius: 50%;
                    //                                                     position: absolute;
                    //                                                     right: -20px;
                    //                                                     top: 0;
                    //                                                     bottom: 0;
                    //                                                 }
                    //                                             }
                    //                                         }
                    //                                         .slots::-webkit-scrollbar{
                    //                                             display: none;
                    //                                         }

                    //                                     }

                    //                                 }
                    //                             }
                    //                         }
                    //                     }
                    //                 }
                    //                 .cardPreview.selected{
                    //                     width: 65%;
                    //                     margin-bottom: 10px;
                    //                     font-family: $font;
                    //                     // min-height: 10% !important;
                    //                     // border: $border;
                    //                     // display: block;
                    //                     .selectedTimeCard{
                    //                         box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.75);
                    //                         height: 60px;
                    //                         margin: auto;
                    //                         display: flex;
                    //                         flex-wrap: nowrap;
                    //                         justify-content: space-between;
                    //                         align-items: center;
                    //                         border-radius: 5px;
                    //                         margin-bottom:20px ;
                    //                         // position: relative;
                    //                         // background-color: #E2D2FE;
                    //                         // color: #5C5173;
                    //                         .cardNumber{
                    //                             width: 10%;
                    //                             margin-left: 10px;
                    //                             position: relative;
                    //                             .numberCircle{
                    //                                 border: $border;
                    //                                 box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
                    //                                 width: 40px;
                    //                                 height: 40px;
                    //                                 border-radius: 50%;
                    //                                 // position: absolute;
                    //                                 text-align: center;
                    //                                 padding-top: 8px;
                    //                                 font-size: 1.1rem;
                    //                             }
                    //                         }
                    //                         .slotDetails{
                    //                             width: 60%;
                    //                             display: flex;
                    //                             flex-direction: column;
                    //                             .day{
                    //                                 font-size: 1.2rem;
                    //                                 font-weight: 500;
                    //                             }
                    //                             .time{
                    //                                 font-weight: 400;
                    //                             }

                    //                         }
                    //                         .slotNote{
                    //                             width: 30%;
                    //                             display: flex;
                    //                             justify-content: flex-end;
                    //                             // position: absolute;
                    //                             // right: 0;
                    //                             .noteBadge{
                    //                                 margin-right:15px ;
                    //                             }
                    //                         }
                    //                     }
                    //                 }
                    //                 .cardPreview.instructor{
                    //                    width: 100%;
                    //                     .instructorTable {
                    //                         width: 100%;
                    //                         display: flex;
                    //                         justify-content: space-between;
                    //                         align-items: center;
                    //                         .instructorForm {
                    //                             width: 40%;
                    //                             margin: 0;

                    //                             .inputCard {
                    //                                 background-color: #ffffff;
                    //                                 box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                    //                                 -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                    //                                 -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                    //                                 margin: 10px;
                    //                                 border-radius: 20px;
                    //                                 padding: 20px;
                    //                                 display: flex;
                    //                                 flex-wrap: wrap;
                    //                                 justify-content: space-between;
                    //                                 align-items: center;
                    //                                 h2 {
                    //                                     font-size: 16px;
                    //                                     margin: 0;
                    //                                 }
                    //                                 svg {
                    //                                     cursor: pointer;
                    //                                 }
                    //                                 .timingContainer {
                    //                                     display: none;
                    //                                     width: 100%;
                    //                                 }
                    //                                 .timingContainer.show {
                    //                                     display: flex;
                    //                                     flex-wrap: wrap;
                    //                                     justify-content: space-between;
                    //                                     align-items: center;
                    //                                     padding: 0;
                    //                                     .timingSlots {
                    //                                         padding: 5px 10px;
                    //                                         border-radius: 20px;
                    //                                         background-color: #4285f4;
                    //                                         color: #ffffff;
                    //                                         margin: 5px 2px;
                    //                                         font-size: 12px;

                    //                                     }
                    //                                     form{
                    //                                         display: inline-block;
                    //                                         width: 100%;
                    //                                         display: flex;
                    //                                         justify-content: space-between;
                    //                                         align-items: center;
                    //                                         .inputContainer{
                    //                                             display: inline-block;
                    //                                             label{
                    //                                                 font-size: 12px;
                    //                                                 margin: 0;
                    //                                             }
                    //                                             input{
                    //                                                 font-size: 12px;
                    //                                             }
                    //                                         }
                    //                                         button{
                    //                                             border: none;
                    //                                             background:none;
                    //                                             svg{
                    //                                                 margin: 0;
                    //                                             }
                    //                                         }
                    //                                     }
                    //                                 }
                    //                             }
                    //                         }
                    //                         .instructorView {
                    //                             width: 60%;
                    //                             margin: 0;
                    //                             padding: 0;

                    //                             .tableFormat {

                    //                                 .row{
                    //                                     display: flex;
                    //                                     align-items: center;
                    //                                     padding: 20px;
                    //                                     .title{
                    //                                         width: 18%;
                    //                                         p{
                    //                                             margin: 0;
                    //                                         }
                    //                                     }
                    //                                     .slots{
                    //                                         width: 68%;
                    //                                         display: flex;
                    //                                         overflow-x: scroll;
                    //                                         .timingSlots {

                    //                                             min-width: 100px;
                    //                                             padding: 5px 10px;
                    //                                             border-radius: 20px;
                    //                                             background-color: #4285f4;
                    //                                             color: #ffffff;
                    //                                             margin: 5px 2px;
                    //                                             font-size: 12px;
                    //                                         }
                    //                                     }
                    //                                     .slots::-webkit-scrollbar{
                    //                                         display: none;
                    //                                     }

                    //                                 }

                    //                             }
                    //                         }
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //         .form.hide {
                    //             display: none;
                    //         }
                    //     }
                    // }
                    .instr {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .instr_select {
                            width: 100%;
                            height: 100%;
                            // border: $border;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .add {
                                width: 100%;
                                min-height: 100px;
                                border: $border;
                                border-radius: $border-radius;
                                text-align: center;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                // justify-content: start;
                                cursor: pointer;
                                margin-top: 5px;
                                padding: 0px 5px;

                                select {
                                    width: 30%;
                                }
                            }
                        }

                        .instr_table {
                            width: 100%;
                            // border: $border;
                        }
                    }
                }

                .formTwoWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    // border: 1px solid red;
                    .formInputs {
                        display: flex;
                        width: 100%;
                        position: relative;

                        // border: 1px solid red;
                        .formWrapper.user {
                            width: 40%;
                        }

                        .cardPreview.user {
                            width: 60%;

                            // max-height: 450px;
                            // border: 1px solid red !important;
                            .instructorTable {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: stretch;

                                .instructorForm {
                                    width: 40%;
                                    margin: 0;

                                    .inputCard {
                                        background-color: #ffffff;
                                        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                                        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                                        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                                        margin: 10px;
                                        border-radius: 20px;
                                        padding: 20px;
                                        display: flex;
                                        flex-wrap: wrap;
                                        justify-content: space-between;
                                        align-items: center;

                                        h2 {
                                            font-size: 16px;
                                            margin: 0;
                                        }

                                        svg {
                                            cursor: pointer;
                                        }

                                        .timingContainer {
                                            display: none;
                                            width: 100%;
                                        }

                                        .timingContainer.show {
                                            display: flex;
                                            flex-wrap: wrap;
                                            justify-content: space-between;
                                            align-items: center;
                                            padding: 0;

                                            .timingSlots {
                                                padding: 5px 10px;
                                                border-radius: 20px;
                                                background-color: #4285f4;
                                                color: #ffffff;
                                                margin: 5px 2px;
                                                font-size: 12px;
                                            }

                                            form {
                                                display: inline-block;
                                                width: 100%;
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;

                                                .inputContainer {
                                                    display: inline-block;

                                                    label {
                                                        font-size: 12px;
                                                        margin: 0;
                                                    }

                                                    input {
                                                        font-size: 12px;
                                                    }
                                                }

                                                button {
                                                    border: none;
                                                    background: none;

                                                    svg {
                                                        margin: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .instructorView.hide {
                                    display: none;
                                }

                                .instructorView.show {
                                    display: inline-block;
                                    width: 100%;
                                    margin: 0;
                                    padding: 0;

                                    .tableFormat {
                                        // padding-top: 30px;
                                        height: auto;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        .row:hover{
                                            // border: 1px solid red;
                                            background-color: gainsboro;
                                            border-radius: 30px;
                                        }

                                        .row {
                                            display: flex;
                                            align-items: center;
                                            // justify-content: space-between;
                                            padding: 8px;

                                            .title {
                                                width: 18%;

                                                p {
                                                    margin: 0;
                                                }
                                            }

                                            .slick-slider {
                                                width: 70% !important;
                                                margin: 0;

                                                .slick-list {
                                                    .timingSlots {
                                                        border: none;
                                                        outline: none;
                                                        min-width: 100px;
                                                        padding: 5px;
                                                        border-radius: 20px;
                                                        background-color: #4285f4;
                                                        color: #ffffff;
                                                        margin: 5px 10px;
                                                        font-size: 11px;
                                                        // display: flex;
                                                        // align-items: center;
                                                        // justify-content: end;
                                                        position: relative;

                                                        span {
                                                            background-color: $colorOrange;
                                                            padding: 5px 10px;
                                                            border-radius: 50%;
                                                            position: absolute;
                                                            right: -20px;
                                                            top: 0;
                                                            bottom: 0;
                                                        }
                                                    }

                                                    .timingSlots.selected {
                                                        border: none;
                                                        outline: none;
                                                        min-width: 100px;
                                                        padding: 5px;
                                                        border-radius: 20px;
                                                        background-color: #121212;
                                                        color: #ffffff;
                                                        margin: 5px 10px;
                                                        font-size: 11px;
                                                        // display: flex;
                                                        // align-items: center;
                                                        // justify-content: end;
                                                        position: relative;

                                                        span {
                                                            background-color: $colorOrange;
                                                            padding: 5px 10px;
                                                            border-radius: 50%;
                                                            position: absolute;
                                                            right: -20px;
                                                            top: 0;
                                                            bottom: 0;
                                                        }
                                                    }
                                                }
                                            }

                                            .slots {
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;
                                                width: 70%;

                                                svg {
                                                    cursor: pointer;
                                                }

                                                .timings {
                                                    width: 80%;
                                                    display: flex;
                                                    justify-content: start;
                                                    overflow-x: scroll;

                                                    .timingSlots {
                                                        border: none;
                                                        outline: none;
                                                        min-width: 100px;
                                                        padding: 5px 10px;
                                                        border-radius: 20px;
                                                        background-color: #4285f4;
                                                        color: #ffffff;
                                                        margin: 5px 10px;
                                                        font-size: 12px;
                                                        // display: flex;
                                                        // align-items: center;
                                                        // justify-content: end;
                                                        position: relative;

                                                        span {
                                                            background-color: $colorOrange;
                                                            padding: 5px 10px;
                                                            border-radius: 50%;
                                                            position: absolute;
                                                            right: -20px;
                                                            top: 0;
                                                            bottom: 0;
                                                        }
                                                    }

                                                    .timingSlots.selected {
                                                        border: none;
                                                        outline: none;
                                                        min-width: 100px;
                                                        padding: 5px 10px;
                                                        border-radius: 20px;
                                                        background-color: #121212;
                                                        color: #ffffff;
                                                        margin: 5px 10px;
                                                        font-size: 12px;
                                                        // display: flex;
                                                        // align-items: center;
                                                        // justify-content: end;
                                                        position: relative;

                                                        span {
                                                            background-color: $colorOrange;
                                                            padding: 5px 10px;
                                                            border-radius: 50%;
                                                            position: absolute;
                                                            right: -20px;
                                                            top: 0;
                                                            bottom: 0;
                                                        }
                                                    }
                                                }
                                            }

                                            .slots .timings::-webkit-scrollbar {
                                                display: none;
                                            }

                                            // .slots {
                                            //     width: 68%;
                                            //     display: flex;
                                            //     overflow-x: scroll;
                                            //     .timingSlots {
                                            //         border: none;
                                            //         outline: none;
                                            //         min-width: 100px;
                                            //         padding: 5px 10px;
                                            //         border-radius: 20px;
                                            //         background-color: #4285f4;
                                            //         color: #ffffff;
                                            //         margin: 5px 10px;
                                            //         font-size: 12px;
                                            //         // display: flex;
                                            //         // align-items: center;
                                            //         // justify-content: end;
                                            //         position: relative;
                                            //         span {
                                            //             background-color: $colorOrange;
                                            //             padding: 5px 10px;
                                            //             border-radius: 50%;
                                            //             position: absolute;
                                            //             right: -20px;
                                            //             top: 0;
                                            //             bottom: 0;
                                            //         }
                                            //     }
                                            //     .timingSlots.selected {
                                            //         border: none;
                                            //         outline: none;
                                            //         min-width: 100px;
                                            //         padding: 5px 10px;
                                            //         border-radius: 20px;
                                            //         background-color: #121212;
                                            //         color: #ffffff;
                                            //         margin: 5px 10px;
                                            //         font-size: 12px;
                                            //         // display: flex;
                                            //         // align-items: center;
                                            //         // justify-content: end;
                                            //         position: relative;
                                            //         span {
                                            //             background-color: $colorOrange;
                                            //             padding: 5px 10px;
                                            //             border-radius: 50%;
                                            //             position: absolute;
                                            //             right: -20px;
                                            //             top: 0;
                                            //             bottom: 0;
                                            //         }
                                            //     }
                                            // }
                                            .slots::-webkit-scrollbar {
                                                display: none;
                                            }
                                        }
                                      
                                    }
                                }
                             
                            }
                        }
                    }

                    .cardPreview.selected {
                        width: 65%;
                        height: 100%;
                        margin-bottom: 10px;
                        font-family: $font;

                        // min-height: 10% !important;
                        // border: $border;
                        // display: block;
                        .selectedTimeCard {
                            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
                            height: 60px;
                            margin: auto;
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            border-radius: 5px;
                            margin-bottom: 20px;

                            // position: relative;
                            // background-color: #E2D2FE;
                            // color: #5C5173;
                            .cardNumber {
                                width: 10%;
                                margin-left: 10px;
                                position: relative;

                                .numberCircle {
                                    border: $border;
                                    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    // position: absolute;
                                    text-align: center;
                                    padding-top: 8px;
                                    font-size: 1.1rem;
                                }
                            }

                            .slotDetails {
                                width: 60%;
                                display: flex;
                                flex-direction: column;

                                .day {
                                    font-size: 1.2rem;
                                    font-weight: 500;
                                }

                                .time {
                                    font-weight: 400;
                                }
                            }

                            .slotNote {
                                width: 30%;
                                display: flex;
                                justify-content: flex-end;

                                // position: absolute;
                                // right: 0;
                                .noteBadge {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }

                    .cardPreview.instructor {
                        width: 100%;

                        .instructorTable {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .instructorForm {
                                width: 40%;
                                margin: 0;

                                .inputCard {
                                    background-color: #ffffff;
                                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                                    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                                    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                                    margin: 10px;
                                    border-radius: 20px;
                                    padding: 20px;
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: space-between;
                                    align-items: center;

                                    h2 {
                                        font-size: 16px;
                                        margin: 0;
                                    }

                                    svg {
                                        cursor: pointer;
                                    }

                                    .timingContainer {
                                        display: none;
                                        width: 100%;
                                    }

                                    .timingContainer.show {
                                        display: flex;
                                        flex-wrap: wrap;
                                        justify-content: space-between;
                                        align-items: center;
                                        padding: 0;

                                        .timingSlots {
                                            padding: 5px 10px;
                                            border-radius: 20px;
                                            background-color: #4285f4;
                                            color: #ffffff;
                                            // margin: 5px 2px;
                                            font-size: 12px;

                                            .hoverDelete {
                                                display: none;
                                            }
                                        }

                                        .timingSlots:hover {
                                            .hoverDelete {
                                                display: inline-block;
                                            }
                                        }

                                        form {
                                            display: inline-block;
                                            width: 100%;
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;

                                            .inputContainer {
                                                display: inline-block;

                                                label {
                                                    font-size: 12px;
                                                    margin: 0;
                                                }

                                                input {
                                                    font-size: 12px;
                                                }
                                            }

                                            button {
                                                border: none;
                                                background: none;

                                                svg {
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .instructorView {
                                width: 60%;
                                margin: 0;
                                padding: 0;
                                height: 100%;

                                .tableFormat {
                               
                                    .row {
                                        display: flex;
                                        align-items: stretch;
                                        padding: 24.5px;
                                        box-sizing: border-box;
                                        .title {
                                            width: 18%;
                                            display: flex;
                                            align-items: center;
                                            p {
                                                margin: 0;
                                            }
                                        }

                                        .slick-slider {
                                            width: 65% !important;
                                            margin: 10px 0;

                                            .slick-list {
                                                span.timingSlots {
                                                    min-width: 180px;
                                                    padding: 0 5px;
                                                    border-radius: 20px;
                                                    background-color: #4285f4;
                                                    color: #ffffff;
                                                    margin: 0px 2px;
                                                    font-size: 11px;
                                                }
                                            }
                                        }

                                        .slots {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            width: 68%;

                                            svg {
                                                cursor: pointer;
                                            }

                                            .timings {
                                                display: flex;
                                                justify-content: space-between;
                                                overflow-x: scroll;

                                                .timingSlots {
                                                    min-width: 140px;
                                                    padding: 5px 10px;
                                                    border-radius: 20px;
                                                    background-color: #4285f4;
                                                    color: #ffffff;
                                                    margin: 5px 2px;
                                                    font-size: 12px;
                                                }
                                            }
                                        }

                                        .slots .timings::-webkit-scrollbar {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .form.hide {
                display: none;
            }

            .purchaseDetails {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .sectionOne {
                    width: 23%;
                    padding: 10px;
                    border-radius: $border-radius;

                    // background: rgb(238, 212, 3);
                    // background: linear-gradient(90deg, rgb(238, 89, 3) 0%, rgb(252, 202, 39) 100%);

                    // box-shadow        : 0px 0px 3px 2px rgba(0, 0, 0, 0.69);
                    // -webkit-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.69);
                    // -moz-box-shadow   : 0px 0px 3px 2px rgba(0, 0, 0, 0.69);

                    .sectionOneHeader {
                        h4 {
                            color: rgb(80, 79, 79);
                        }
                    }

                    .sectionOneContent {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }

                .sectionTwo {
                    width: 75%;
                    height: 100%;
                    padding: 10px;
                    border-radius: $border-radius;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;

                    // background: rgb(238, 212, 3);
                    // background: linear-gradient(90deg, rgb(238, 89, 3) 0%, rgb(252, 202, 39) 100%);

                    // box-shadow        : 0px 0px 3px 2px rgba(0, 0, 0, 0.69);
                    // -webkit-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.69);
                    // -moz-box-shadow   : 0px 0px 3px 2px rgba(0, 0, 0, 0.69);

                    .sectionTwoHeader {
                        h4 {
                            color: whitesmoke;
                        }
                    }

                    .sectionTwoContent {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                    }
                }
            }
            .profileContent {
                width: 100%;
                margin: auto;
                max-width: 945px;
                height: 80%;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                // background-color: #ececec;
                padding: 2%;
                border-radius: 20px;

                .sectionLeft {
                    width: 48%;
                    height: 100%;
                    .cardProfile {
                        height: 100%;
                        border-radius: 20px;
                        background-color: white;
                        box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                        -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);

                        .profilePic {
                            width: 100%;
                            height: 50%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 20px 20px 0px 0px;
                            }
                        }

                        .profileDetails {
                            width: 100%;
                            height: 50%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 1%;

                            .detailItem {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                                padding: 3%;
                                h4 {
                                    margin-bottom: 0 !important;
                                }
                                span {
                                    font-size: 1rem;
                                    border-bottom: 1px solid #270b8273;
                                    padding-bottom: 5px;
                                    width: 48%;
                                }
                                span.mail {
                                    width: 100%;
                                }
                                span.smsalert {
                                    border: none;
                                    color: #f05e3c;
                                }
                                span.active {
                                    border: none;
                                    width: 20px;
                                    height: 20px;
                                    background: linear-gradient(
                                        90deg,
                                        rgba(248, 112, 60, 1) 0%,
                                        rgba(251, 55, 128, 1) 100%
                                    );
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }

                .sectionRight {
                    width: 48%;
                    padding: 2px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .cards {
                        height: 48%;
                        // border: $border;
                        border-radius: 20px;
                        display: flex;
                        flex-direction: column;
                        background-color: white;
                        box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                        -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                        .cardHeader {
                            min-height: 65px;
                            border-bottom: 1px solid whitesmoke;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            .cardTitle {
                                margin-left: 10px;
                                font-size: large;
                                font-weight: 500;
                            }
                            .toolSpace {
                                .searchIcon {
                                    margin: 5%;
                                    cursor: pointer;
                                }
                                .editButton {
                                    border-radius: 26px;
                                    padding: 4% 10%;
                                }
                            }
                        }
                        .cardBody {
                            display: flex;
                            flex-direction: column;
                            overflow-y: scroll;
                            -ms-overflow-style: none; /* for Internet Explorer, Edge */
                            scrollbar-width: none; /* for Firefox */
                            .accountsItem {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                                margin: 15px;
                                .paidStatus {
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    background: linear-gradient(
                                        90deg,
                                        rgba(180, 199, 60, 1) 0%,
                                        rgba(9, 153, 0, 1) 100%
                                    );
                                }
                                .notPaidStatus {
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    background: linear-gradient(
                                        90deg,
                                        rgba(248, 112, 60, 1) 0%,
                                        rgba(251, 55, 128, 1) 100%
                                    );
                                }
                                .details {
                                    .statusType {
                                        font-size: 16px;
                                    }
                                    .accountId {
                                        opacity: 0.9;
                                    }
                                }
                                span {
                                    color: white;
                                    border-radius: 25px;
                                }
                                .bAccount {
                                    background: linear-gradient(
                                        90deg,
                                        rgba(248, 112, 60, 1) 0%,
                                        rgba(251, 55, 128, 1) 100%
                                    );
                                }
                                .ubAccount {
                                    background: linear-gradient(
                                        90deg,
                                        rgba(180, 199, 60, 1) 0%,
                                        rgba(9, 153, 0, 1) 100%
                                    );
                                }
                            }
                        }
                        .cardBody::-webkit-scrollbar {
                            display: none;
                        }
                    }
                }
            }
        }

        .contentContainer.dash {
            position: relative;
            bottom: 90px;
        }
    }

    .rightContainer::-webkit-scrollbar {
        // display: none;
    }

    .rightContainer::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
    }

    .rightContainer::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
    }

    .rightContainer::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #270b82;
    }
}

// .mainWrapper::-webkit-scrollbar {
//     display: none;
//   }

//   /* Hide scrollbar for IE, Edge and Firefox */
//   .mainWrapper {
//     -ms-overflow-style: none;  /* IE and Edge */
//     scrollbar-width: none;  /* Firefox */
//   }
#datepicker {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

.classCard {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    margin: 10px;

    .number {
        // width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        border-radius: 50%;
        text-align: center;
    }

    .info {
        // width: 40%;
        padding: 5px;
        display: flex;
        justify-content: start;
        // align-items: center;
        flex-direction: column;

        .date {
            margin: 0;
            font-weight: 500;
        }

        .time {
            margin: 0;
        }
    }

    .day {
        // width: 20%;
        margin: 0;
        margin-right: 30px;
        white-space: nowrap;
    }
}

// input[type="time"]::-webkit-calendar-picker-indicator {
//     background: none;
// }

.available {
    font-size: 18px;
    background-color: green;
    border-radius: 20px;
    color: #ffffff;
    text-align: center;
    width: 50%;
    margin: auto;
}

.notAvailable {
    font-size: 18px;
    background-color: red;
    border-radius: 20px;
    color: #ffffff;
    text-align: center;
    width: 50%;
    margin: auto;
}

.toolSpace {
    // border: 1px solid red;
    width: 25%;

    section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .profile {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 80%;

            img {
                border-radius: 50%;
                margin: 0px 10px;
            }

            p {
                margin: 0;
                font-size: 13px;
                font-weight: 500;
            }
        }

        svg {
            cursor: pointer;
        }
    }

    .toggleButton {
        color: white;
        cursor: pointer;
        display: none;
    }

    /* .searchBar {
        color: white;
        cursor: pointer;
    } */
    .sb {
        .tb {
            display: table;
            width: 100%;
        }

        .td {
            display: table-cell;
            vertical-align: middle;
        }

        input {
            color: #fff;
            font-family: Nunito;
            padding: 0;
            margin: 0;
            border: 0;
            background-color: transparent;
        }

        #cover {
            padding: 35px;
            margin: 0 auto;
            margin-top: 40px;
            background-color: #ff7575;
            border-radius: 20px;
            box-shadow: 0 10px 40px #ff7c7c, 0 0 0 20px #ffffffeb;
            -webkit-transform: scale(0.6);
            transform: scale(0.6);
        }

        // form {
        //     height: 96px;
        // }
        input[type="text"] {
            width: 100%;
            height: 96px;
            font-size: 60px;
            line-height: 1;

            &::-webkit-input-placeholder {
                color: #e16868;
            }

            &:-ms-input-placeholder {
                color: #e16868;
            }

            &::-ms-input-placeholder {
                color: #e16868;
            }

            &::placeholder {
                color: #e16868;
            }
        }

        #s_cover {
            width: 1px;
            padding-left: 35px;

            &:hover {
                #s-circle {
                    top: -1px;
                    width: 67px;
                    height: 15px;
                    border-width: 0;
                    background-color: #fff;
                    border-radius: 20px;
                }

                span {
                    top: 50%;
                    left: 56px;
                    width: 25px;
                    margin-top: -9px;
                    -webkit-transform: rotateZ(0);
                    transform: rotateZ(0);
                }

                button {
                    span {
                        &:before {
                            bottom: 11px;
                            -webkit-transform: rotateZ(52deg);
                            transform: rotateZ(52deg);
                            right: -6px;
                            width: 40px;
                            background-color: #fff;
                        }

                        &:after {
                            bottom: -11px;
                            -webkit-transform: rotateZ(-52deg);
                            transform: rotateZ(-52deg);
                            right: -6px;
                            width: 40px;
                            background-color: #fff;
                        }
                    }
                }
            }
        }

        button {
            position: relative;
            display: block;
            width: 84px;
            height: 96px;
            cursor: pointer;

            span {
                position: absolute;
                top: 68px;
                left: 43px;
                display: block;
                width: 45px;
                height: 15px;
                background-color: transparent;
                border-radius: 10px;
                -webkit-transform: rotateZ(52deg);
                transform: rotateZ(52deg);
                transition: 0.5s ease all;

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 45px;
                    height: 15px;
                    background-color: #fff;
                    border-radius: 10px;
                    -webkit-transform: rotateZ(0);
                    transform: rotateZ(0);
                    transition: 0.5s ease all;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 45px;
                    height: 15px;
                    background-color: #fff;
                    border-radius: 10px;
                    -webkit-transform: rotateZ(0);
                    transform: rotateZ(0);
                    transition: 0.5s ease all;
                }
            }
        }

        #s-circle {
            position: relative;
            top: -8px;
            left: 0;
            width: 43px;
            height: 43px;
            margin-top: 0;
            border-width: 15px;
            border: 15px solid #fff;
            background-color: transparent;
            border-radius: 50%;
            transition: 0.5s ease all;
        }
    }

    button {
        color: #fff;
        font-family: Nunito;
        padding: 0;
        margin: 0;
        border: 0;
        background-color: transparent;
    }

    .sb-example-3 {
        .search__title {
            font-size: 22px;
            font-weight: 900;
            text-align: center;
            color: #ff8b88;
        }

        .search__input {
            width: 100%;
            padding: 12px 24px;
            background-color: transparent;
            transition: transform 250ms ease-in-out;
            font-size: 14px;
            line-height: 18px;
            color: #575756;
            background-color: transparent;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: 18px 18px;
            background-position: 95% center;
            border-radius: 50px;
            border: 1px solid #575756;
            transition: all 250ms ease-in-out;
            backface-visibility: hidden;
            transform-style: preserve-3d;

            &::placeholder {
                color: rgba(87, 87, 86, 0.8);
                text-transform: uppercase;
                letter-spacing: 1.5px;
            }

            &:hover {
                padding: 12px 0;
                outline: 0;
                border: 1px solid transparent;
                border-bottom: 1px solid #575756;
                border-radius: 0;
                background-position: 100% center;
            }
        }
    }

    .search__input {
        &:focus {
            padding: 12px 0;
            outline: 0;
            border: 1px solid transparent;
            border-bottom: 1px solid #575756;
            border-radius: 0;
            background-position: 100% center;
        }
    }
}

.search {
    width: 60px;
    height: 60px;
    transform: 0.5s;
    background: #fff;
    overflow: hidden;
    position: relative;
    border-radius: 60px;
    box-shadow: 0 0 0 0 5px #2573ef;
}

.search.active {
    width: 360px;
}

.search .icon {
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    display: flex;
    z-index: 1000;
    cursor: pointer;
    position: absolute;
    background: #fff;
    border-radius: 60px;
    align-items: center;
    justify-content: center;
}

.search .icon:before {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #287dfc;
    transform: translate(-4px, -4px);
}

.search .icon:after {
    content: "";
    width: 3px;
    height: 12px;
    position: absolute;
    background: #287dfc;
    transform: translate(6px, 6px) rotate(315deg);
}

.search .input {
    left: 60px;
    width: 300px;
    height: 60px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.search .input input {
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    padding: 10px 0;
    position: absolute;
}

.clear {
    top: 50%;
    right: 15px;
    width: 15px;
    height: 15px;
    display: flex;
    cursor: pointer;
    background: #ff0;
    position: relative;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
}

.clear:before {
    width: 1px;
    height: 15px;
    content: "";
    position: absolute;
    background: #999;
    transform: rotate(45deg);
}

.clear:after {
    width: 1px;
    height: 15px;
    content: "";
    position: absolute;
    background: #999;
    transform: rotate(315deg);
}

.form-check-input:checked {
    background-color: #308d05 !important;
    border-color: #308d05 !important;
}

.slick-prev:before,
.slick-next:before {
    color: #121212 !important;
}

.purchaseDetails {
    // display: flex;
    margin-bottom: 30px;

    .form {
        padding: 20px;
        display: flex;

        .input {
            // border: 1px solid red;
            position: relative;
            width: 20%;
            display: flex;
            justify-content: space-between;
            padding: 20px;

            button.purchaseBtn {
                border: none;
                background: none;
                border-radius: 50%;
                border: 1px solid gray;
                // padding: 10px;
                font-size: 20px;
            }

            input {
                width: 25%;
                border: none;
                background: none;
                font-size: 20px;
            }
        }
    }

    .details {
        display: flex;
        justify-content: space-between;

        .circle {
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.dropbtn {
    // background-color: #04AA6D;
    // color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

.dropbtn svg {
    fill: black;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: -150px;
}

.dropdown-content.two {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: -100px;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn svg {
    fill: #270b82;
}

.welcomeCard {
    max-width: 424px;
    max-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 10px 20px;
    margin-left: 40px;
    background-color: #ffa000;
    border-radius: 10px;
    position: relative;
    top: -90px;
    left: -30px;

    .info {
        padding: 11px 13px;

        h1 {
            font-weight: 500;
            font-size: 30px;
            color: #ffffff;
            margin: 0;
        }

        p {
            font-weight: 400;
            color: #ffffff;
            font-size: 13px;
            margin: 0;
        }
    }

    .image {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: end;

        img {
            width: 100%;
            max-width: 100px;
            height: 100%;
            border-radius: 0px 10px 10px 0px;
        }
    }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.progress {
    width: 100px;
    height: 100px;
    font-size: 24px;
    color: #fff;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    text-align: center;
    line-height: 200px;
    margin: 20px;
}

.progress::after {
    content: "%";
}

.progress .title {
    position: relative;
    z-index: 100;
    left: 50%;
    // top: 50%;
    transform: translate(-50%, -50%);
    color: #07070c;
}

.progress .overlay {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #ffffff;
}

.progress .left,
.progress .right {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 10px solid #ee9b00;
    border-radius: 100px 0px 0px 100px;
    border-right: 0;
    transform-origin: right;
}

.progress .left {
    animation: load1 1s linear forwards;
}

.progress:nth-of-type(2) .right,
.progress:nth-of-type(3) .right {
    animation: load2 0.5s linear forwards 1s;
}

.progress:last-of-type .right,
.progress:first-of-type .right {
    animation: load3 0.8s linear forwards 1s;
}

@keyframes load1 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes load2 {
    0% {
        z-index: 100;
        transform: rotate(180deg);
    }

    100% {
        z-index: 100;
        transform: rotate(270deg);
    }
}

@keyframes load3 {
    0% {
        z-index: 100;
        transform: rotate(180deg);
    }

    100% {
        z-index: 100;
        transform: rotate(315deg);
    }
}

.sectionOne {
    height: 80vh;
    width: 40%;
    padding: 0;

    .purchaseCard {
        width: 100%;
        background: rgb(15, 1, 98);
        background: linear-gradient(257deg, rgba(15, 1, 98, 1) 34%, rgba(16, 133, 246, 1) 100%);
        padding: 20px;
        margin: 20px;
        height: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;

        .purchaseCardTitle {
            width: 80%;
            padding: 5px;
            background-color: #fec626;
            border-radius: 10px;
            color: #ffffff;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            border: 1px solid black;
        }

        .content {
            position: relative;
            width: 100%;
            color: #ffffff;
            font-weight: 500;
            font-size: 18px;
            display: flex;
            // flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .ant-progress-circle {
                // margin-top: 10px;
                position: absolute;
                left: 50%;
                top: 50%;
                z-index: 110;
                transform: translate(-50%, -50%);
                width: 120px;
                height: 120px;

                .ant-progress-inner {
                    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.74);

                    .ant-progress-text {
                        color: black !important;
                        z-index: 999 !important;
                        position: absolute;
                        top: 60px;
                    }
                }

                .ant-progress-circle-trail {
                    stroke: #ffffff00 !important;
                }
            }

            .progressBg {
                // background      : rgba(255, 255, 255, 0);
                // height          : 135px;
                // width           : 135px;
                // border-radius   : 50%;
                // position        : absolute;
                // top             : 1%;
                // left            : 38%;
                // z-index  : -1;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-39%, -39%);
                z-index: 100;
                // left     : 50%;
                // transform: translateX(-50%);

                // box-shadow   : 0px 0px 9px 0px rgba(0, 0, 0, 0.74);

                // width : 90px;
                // height: 90px;
            }

            .progressFg {
                // height  : 95px;
                // width   : 95px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-39%, -39%);
                z-index: 109;
                // top     : 7%;
                // left    : 39%;
                // width  : 90px;
                // height : 90px;
                // z-index: 93;

                // box-shadow   : 0px 0px 9px 0px rgba(0, 0, 0, 0.74);
                svg {
                    position: relative;

                    image {
                        // position: absolute;
                        // top: 50%;
                        // left: 50%;
                        // transform: translate(-50%,-50%);
                    }

                    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.74);
                }
            }

            .progressLine {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 2%;

                .ant-progress {
                    .ant-progress-outer {
                        background: #f5f5f5;
                        border-radius: 30px;
                        padding: 0px 5px;
                        position: relative;
                        top: 30px;

                        .ant-progress-inner {
                            .ant-progress-bg {
                                background: linear-gradient(
                                    90deg,
                                    rgba(3, 8, 238, 1) 0%,
                                    rgba(252, 39, 39, 1) 100%
                                ) !important;
                            }
                        }
                    }

                    .ant-progress-text {
                        color: white !important;
                        font-size: 2rem;
                        position: relative;
                        top: -30px;
                    }
                }

                .ant-btn-group {
                    width: 25% !important;
                    position: relative !important;
                    display: flex !important;
                    flex-wrap: nowrap !important;
                    justify-content: space-evenly !important;
                    margin-top: 3%;

                    button {
                        width: 45px;
                        height: 45px;
                        border-radius: 15px;
                        /* padding   : 4px; */
                        background: linear-gradient(90deg, rgba(238, 58, 3, 1) 0%, rgba(255, 183, 0, 1) 100%);

                        box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;
                        -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;
                        -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;

                        .anticon {
                            background: white;
                            margin: auto;
                            width: 80%;
                            height: 80%;
                            display: flex;
                            align-items: center;
                            border-radius: 10px;
                            box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;
                            -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;
                            -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75) !important;

                            svg {
                                font-size: x-large;
                            }
                        }
                    }
                }
            }
        }

        button.purchase {
            background-image: linear-gradient(to right, #fec626 0%, #ff3c00 51%, #fec626 100%);
            width: 50%;
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;
            border-radius: 20px;
            height: 35px;
        }

        button.purchase:hover {
            background-position: right center;
            /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
        }

        button.history {
            // background-color: #ee9b00;
            // border-radius: 20px;
            width: 70%;
            // margin: auto;
            // border: none;
            // outline: none;
            // padding: 7px;
            // color: #f1f1f1;
            // font-size: 18px;
            // margin: auto;
            left: 50%;
            transform: translateX(-50%);

            background-image: linear-gradient(to right, #fec626 0%, #ff3c00 51%, #fec626 100%);
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;
            // box-shadow: 0 0 20px #eee;
            border-radius: 20px;
            display: block;
            height: 35px;
        }

        button.history:hover {
            background-position: right center;
            /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
        }
    }
}

.btn-grad {
    background-image: linear-gradient(to right, #403b4a 0%, #e7e9bb 51%, #403b4a 100%);
}

.btn-grad {
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
}

.btn-grad:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

// ******* profile component ********

// border: 1px solid red;

.ProfileContainer {
    display: flex;
    padding: 20px;
    font-family: "Poppins", sans-serif;
    .cardOne {
        width: 50%;
        height: 100%;
        border-radius: 20px;
        background-color: #e0e0e0;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        .image {
            width: 100%;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            img {
                width: 100%;
                max-height: 350px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                filter: brightness(80%);
            }
        }
        .info {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            padding: 30px 10px;
            position: relative;
            top: -30px;
            background-color: #e0e0e0;
            .title {
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                .data {
                    font-size: 16px;
                    font-weight: 400;
                    color: #a4a4a4;
                }
            }
        }
    }
    .cardTwo {
        width: 50%;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .subCardOne {
            height: 50%;
            padding: 30px;
            background-color: #e0e0e0;
            border-radius: 20px;
            margin: 30px 0px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
            .header {
                font-weight: 700;
                font-family: "Poppins", sans-serif;
                font-size: 24px;
            }
            .data {
                font-size: 16px;
                font-weight: 400;
                color: #a4a4a4;
            }
        }
        .subCardTwo {
            height: 50%;
            padding: 30px;
            background-color: #e0e0e0;
            border-radius: 20px;
            margin: 30px 0px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        }
    }
}

.schedulePreview {
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
padding: 10px;
width: 150px;
border-radius: 30px;
display: flex;
justify-content: space-between;
margin: 20px;
p.date{
    margin:0;
    font-weight:500;
}
p.time{
    margin: 0;
    font-weight:500;
}
}

.schedulePreview.warning {
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
padding: 10px;
width: 200px;
border-radius: 30px;
display: flex;
justify-content: space-between;
margin: 20px;
background-color: #DC3545;
p.date{
    margin:0;
    font-weight:500;
    color: #ffffff;
}
p.time{
    margin: 0;
    font-weight:500;
    color: #ffffff;

}
}

.ant-picker-dropdown{
    z-index: 1056 !important;
}